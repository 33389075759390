@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body {
    padding: 0;
    margin: 0;
    font-family: 'Open Sans', sans-serif !important;
}

/*---Scrollbar Styles---*/
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background: #fff;
}

::-webkit-scrollbar-thumb {
    background: #b2b0b6;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: #888;
}

.sort-icons .active {
    color: #27a6a4;
}

.sort-icons {
    color: #9c9aa1;
}

.sort-icons {
    rd-icon:last-child {
        margin-top: -4px !important;
    }
}
.ant-breadcrumb .ng-star-inserted .ant-breadcrumb-link {
    color: #1a1628;
    font-weight: 700;
    font-size: 16px;
    a.ng-star-inserted {
        font-size: 16px !important;
        color: #2d8b93;
        font-weight: 400;
        font-style: italic;
    }
}
.ant-tag.tileClass.border-change {
    background: #fff !important;
    border: 1px solid #dedcde !important;
    background: #fff !important;
    box-shadow: 2px 2px 4px 0px rgba(163, 170, 174, 0.45) !important;
}
.ant-select-item-option-content {
    font-size: 16px;
}

// THE LOADING EFFECT
.skeleton {
    border-radius: 2px;
    background-color: #dedede;
    // The shine that's going to move across the skeleton:
    background-image: linear-gradient(
        90deg,
        rgba(#fff, 0),
        rgba(#fff, 0.5),
        rgba(#fff, 0)
    );
    background-size: 40px 100%; // width of the shine
    background-repeat: no-repeat; // No need to repeat the shine effect
    background-position: left -40px top 0; // Place shine on the left side, with offset on the left based on the width of the shine - see background-size
    animation: shine 1s ease infinite; // increase animation time to see effect in 'slow-mo'
}

@keyframes shine {
    to {
        // Move shine from left to right, with offset on the right based on the width of the shine - see background-size
        background-position: right -40px top 0;
    }
}
